<template>
  <!--
    The view for the LayoutImageList-component.
    Used to display the themes LayoutImageElements.
  -->
  <div class="layoutImageOverview">
    <LayoutImageList 
      ref="layoutImageList"
      :theme-id="themeId"
      @reloadAuditLogs="reloadAuditLogs"
    />
    <DatabaseAuditLogList
      ref="databaseAuditLogList"
      :theme-id="themeId"
      :is-theme-elements="true"
      @reload="reloadLayoutElementList"
    />
  </div>
</template>

<script>
export default {
  name: "LayoutImageOverview",
  components: {
    LayoutImageList: () => import('@/components/VideoPostprocessing/Theme/LayoutImageList.vue'),
    DatabaseAuditLogList: () => import('@/components/DatabaseAuditLogs/DatabaseAuditLogsList.vue')
  },
  props: {
    themeId: {
      type: String,
      required: true
    }
  },
  metaInfo () {
    return {
      title: this.$t('videoPostProcessing.title')
    }
  },
  methods: {
    reloadAuditLogs () {
      this.$refs.databaseAuditLogList.reloadAuditLogs();
    },
    reloadLayoutElementList () {
      this.$refs.layoutImageList.closeAndReloadData();
    },
  }
}
</script>
